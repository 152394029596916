export default class CardExpandableBody extends React.Component {
  static defaultProps = {
    withPadding: true
  }

  static propTypes = {
    bodyClassName: PropTypes.string,
    header: PropTypes.node.isRequired,
    withPadding: PropTypes.bool.isRequired
  }

  state = ({
    expanded: false
  })

  render() {
    const {bodyClassName, children, className, header, withPadding, ...restProps} = this.props
    const {expanded} = digs(this.state, "expanded")

    return (
      <>
        <div className={classNames("components-card-expandable-body", className)} data-expanded={expanded} {...restProps}>
          <div className="d-flex">
            <div className="expandable-body-header flex-grow-1">
              {header}
            </div>
            <div className="expandable-body-toggle">
              {expanded &&
                <a className="collapse-expandable-body-link" href="#" onClick={this.onCollapseClicked}>
                  <i className="la la-angle-up" />
                </a>
              }
              {!expanded &&
                <a className="expand-expandable-body-link" href="#" onClick={this.onExpandClicked}>
                  <i className="la la-angle-down" />
                </a>
              }
            </div>
          </div>
        </div>
        {expanded &&
          <div className={classNames("components-card-expandable-body-children", bodyClassName)} data-with-padding={withPadding}>
            {children}
          </div>
        }
      </>
    )
  }

  onCollapseClicked = (e) => {
    e.preventDefault()
    this.setState({expanded: false})
  }

  onExpandClicked = (e) => {
    e.preventDefault()
    this.setState({expanded: true})
  }
}
