import "./style"
import {Outlet, useNavigate} from "react-router-dom"
import {Suspense, useContext} from "react"
import Container from "react-bootstrap/Container"
import {Navbar} from "react-bootstrap"
import {UserContext} from "shared/user-context"
import UsersSignOut from "nemoa/components/users/sign-out"
import {useSchoolElection} from "shared/hooks/queries/use-school-election"
import {useTitle} from "shared/hooks/use-title"

export default function Layout() {
  useTitle("Valg")
  const {data: schoolElection} = useSchoolElection()
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()
  const onHomeClick = (e) => {
    e.preventDefault()

    navigate(`/school_elections/${schoolElection.id()}`)
  }
  const onSignOutClicked = (e) => {
    e.preventDefault()

    UsersSignOut().then(() => navigate("/sign_in"))
  }

  return (
    <div className="election-components-layout">
      <header className="p-3 mb-3 border-bottom">
        <Navbar>
          <Container>
            <Navbar.Brand href="#" onClick={onHomeClick}>
              {`${schoolElection?.translatedElectionType() || "Valg"} ${new Date().getFullYear()}`}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Logget ind som <a href="#login">{currentUser?.name()}</a> (<a href="#" onClick={onSignOutClicked}>log af</a>)
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <div className="container">
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}
