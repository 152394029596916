export default class AdminElectionPostsVotingCounts extends React.Component {
  static defaultProps = {
    textMuted: false
  }

  static propTypes = {
    className: PropTypes.string,
    electionPost: PropTypes.instanceOf(ElectionPost).isRequired,
    textMuted: PropTypes.bool.isRequired
  }

  state = ({
    votesCount: this.props.electionPost.votesCount(),
    voteTokensCount: this.props.electionPost.voteTokensCount()
  })

  render() {
    const {className, electionPost, textMuted, ...restProps} = this.props
    const {votesCount, voteTokensCount} = digs(this.state, "votesCount", "voteTokensCount")

    return (
      <div className={classNames("admin-components-election-posts-voting-counts", className, {"text-muted": textMuted})} {...restProps}>
        <EventConnection event="vote_cast" model={electionPost} onCall={this.onVoteCast} />
        <EventConnection event="vote_token_created" model={electionPost} onCall={this.onVoteTokenCreated} />

        [{votesCount}/{voteTokensCount}]
      </div>
    )
  }

  onVoteCast = () => {
    this.loadCountsLater()
  }

  onVoteTokenCreated = () => {
    this.loadCountsLater()
  }

  loadCounts = async () => {
    const {electionPost} = digs(this.props, "electionPost")
    const electionPostResponse = await ElectionPost
      .ransack({id_eq: electionPost.id()})
      .select({ElectionPost: ["votesCount", "voteTokensCount"]})
      .first()

    this.setState({
      votesCount: electionPostResponse.votesCount(),
      voteTokensCount: electionPostResponse.voteTokensCount()
    })
  }

  loadCountsLater = debounce(this.loadCounts, 50)
}
