import "./style"

export default class AdminActivitiesLiveAgendaItemTypesElectionVoteIcon extends React.Component {
  static defaultProps = {
    disabled: false
  }

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.oneOf(["n", "selected", "thumbs-down", "thumbs-up"]).isRequired
  }

  render() {
    const {className, disabled, icon, ...restProps} = this.props

    return (
      <div
        className={classNames("admin-components-activities-live-agenda-item-types-election-vote-icon", className)}
        data-icon={this.icon()}
        style={{backgroundImage: `url("${this.iconComponent()}"`}}
        {...restProps}
      />
    )
  }

  icon() {
    const {disabled, icon} = digs(this.props, "disabled", "icon")

    if (disabled) {
      return `${icon}-disabled`
    }

    return icon
  }

  iconComponent() {
    return require(`./${this.icon()}`)
  }
}
