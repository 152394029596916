import {generatePath, Link, useParams} from "react-router-dom"
import ActivitiesElectionsElectionPostCard from "nemoa/components/activities/elections/election-post-card"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import {useContext} from "react"
import {useElectionPosts} from "shared/hooks/queries/use-election-posts"
import {UserContext} from "shared/user-context"

function YourElectionPosts({contactId, schoolClassId, schoolElectionId}) {
  const {data: electionPosts, isLoading} = useElectionPosts({schoolClassId, schoolElectionId})
  const filteredElectionPosts = electionPosts?.filter((electionPost) =>
    electionPost.state() !== "election_active" &&
    electionPost.state() !== "result_shown" &&
    electionPost.state() !== "processing_result")

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      {filteredElectionPosts?.length > 0 &&
        <Row>
          {filteredElectionPosts?.map((electionPost) =>
            <Col key={electionPost.id()} lg={6}>
              <ActivitiesElectionsElectionPostCard contactId={contactId} electionPost={electionPost} />
            </Col>
          )}
        </Row>
      }
      {filteredElectionPosts?.length === 0 &&
        <Alert variant="info">
          Der blev ikke fundet valgposter, hvor du er opstillingsberettiget.
          Hvis valget allerede er igang kan du afgive din stemme <Link to={generatePath(`/school_elections/${schoolElectionId}/vote`)}>her</Link>.
        </Alert>
      }
    </>
  )
}

export default function SchoolElectionsSignUp() {
  const {id: schoolElectionId} = useParams()
  const [currentUser] = useContext(UserContext)

  return (
    <div>
      <YourElectionPosts
        contactId={currentUser.contact().id()}
        schoolClassId={currentUser.currentSchoolYear().schoolClassId()}
        schoolElectionId={schoolElectionId}
      />
    </div>
  )
}
