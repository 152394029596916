import {generatePath, Link, useParams, useSearchParams} from "react-router-dom"
import Alert from "react-bootstrap/Alert"
import ElectionPostCard from "admin/components/activities/live/agenda/item-types/election/election-post-card"
import Stack from "react-bootstrap/Stack"
import {useElectionPosts} from "shared/hooks/queries/use-election-posts"
import {useSchoolElection} from "shared/hooks/queries/use-school-election"

export default function ElectionsShow() {
  const {id: schoolElectionId} = useParams()
  const [searchParams] = useSearchParams()
  const schoolClassId = searchParams.get("school_class_id")
  const {data: electionPosts, refetch} = useElectionPosts({schoolElectionId, schoolClassId})
  const {data: schoolElection} = useSchoolElection({id: schoolElectionId})

  return (
    <>
      {electionPosts?.map((electionPost) =>
        <EventUpdated key={electionPost.id()} model={electionPost} onUpdated={() => refetch()} />
      )}
      <h1 className="mb-4">{schoolElection?.translatedElectionType()}</h1>
      <Stack gap={3}>
        {electionPosts?.map((electionPost) =>
          <ElectionPostCard electionPost={electionPost} key={electionPost.id()} newStateSelector />
        )}
        {electionPosts?.length === 0 &&
          <Alert variant="info">
            Der blev ikke fundet valgposter, hvor du er stemmeberettiget.
            Ønsker du i stedet at stille op til valget, skal du klikke <Link to={generatePath(`/school_elections/${schoolElectionId}/sign_up`)}>her</Link>.
          </Alert>
        }
      </Stack>
    </>
  )
}
