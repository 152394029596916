import {selectorFamily, useRecoilValue} from "recoil"
import {useCallback, useContext, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import {SchoolElection} from "@kaspernj/api-maker/src/models"
import {UserContext} from "shared/user-context"

const schoolClassesSelectorFamily = selectorFamily({
  key: "schoolClassesSelector",
  get: (schoolId) => async() => {
    const schoolClasses = await SchoolClass.ransack({school_id_eq: schoolId}).toArray()

    return schoolClasses
  }
})

function ElectionPostGroup({electionPost, onChange, onDestroyClicked, showDestroy}) {
  return (
    <ListGroup.Item variant="flush">
      <Row>
        <Col>
          <Form.Group controlId={`election-post-group-name-${electionPost.id()}`}>
            <Form.Label>{ElectionPost.humanAttributeName("name")}</Form.Label>
            <Form.Control
              name={`school_election[election_attributes][election_post_attributes][${electionPost.cacheKey()}][name]`}
              onChange={(e) => onChange(e, "name")}
              size="sm"
              value={electionPost.name() || ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`election-post-group-seats-${electionPost.uniqueKey()}`}>
            <Form.Label>{ElectionPost.humanAttributeName("seats")}</Form.Label>
            <Form.Control
              name={`school_election[election_attributes][election_post_attributes][${electionPost.cacheKey()}][seats]`}
              onChange={(e) => onChange(e, "seats")}
              size="sm"
              type="number"
              value={electionPost.seats() || ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`election-post-group-votes-required-${electionPost.uniqueKey()}`}>
            <Form.Label>{ElectionPost.humanAttributeName("votesRequired")}</Form.Label>
            <Form.Control
              name={`school_election[election_attributes][election_post_attributes][${electionPost.cacheKey()}][votes_required]`}
              onChange={(e) => onChange(e, "votesRequired")}
              placeholder="Efterlad blank, hvis dette skal udregnes på baggrund af opstillede."
              size="sm"
              type="number"
              value={electionPost.votesRequired() || ""}
            />
          </Form.Group>
        </Col>
        {showDestroy &&
          <Col className="align-items-center d-flex" sm={1}>
            <Icon icon="trash" onClick={onDestroyClicked} />
          </Col>
        }
      </Row>
    </ListGroup.Item>
  )
}

export default function SchoolElectionsNew() {
  const navigate = useNavigate()
  const [currentUser] = useContext(UserContext)
  const [checked, setChecked] = useState({})
  const [electionPosts, setElectionPosts] = useState([new ElectionPost({name: I18n.t("js.election.school_elections.new.student_council_post"), seats: 1})])
  const [schoolElection] = useState(new SchoolElection())
  const [searchParams] = useSearchParams()
  const electionType = searchParams.get("school_election[election_type]")
  const schoolClasses = useRecoilValue((schoolClassesSelectorFamily(currentUser.currentSchoolYear().school().id())))

  const onCheckChanged = (e) => {
    setChecked((checked) => ({
      ...checked,
      [e.target.value]: e.target.checked
    }))
  }

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    const selectedSchoolClasses = Object.entries(checked)
      .filter(([_key, value]) => value)
      .map(([key]) => schoolClasses.find((schoolClass) => schoolClass.id() === key))

    const electionPostAttributes = () => {
      if (electionType === "class_election") {
        return selectedSchoolClasses.flatMap((schoolClass) =>
          electionPosts.map((electionPost) => ({
            deadline: "20-02-2020",
            name: electionPost.name(),
            seats: electionPost.seats(),
            visible: true,
            election_post_school_classes_attributes: [{
              school_class_id: schoolClass.id()
            }]
          }))
        )
      }

      return electionPosts.map((electionPost) => ({
        deadline: "20-02-2020",
        name: electionPost.name(),
        seats: electionPost.seats(),
        visible: true,
        election_post_school_classes_attributes: selectedSchoolClasses.map((schoolClass) => ({
          school_class_id: schoolClass.id()
        }))
      }))
    }

    schoolElection.assignAttributes({
      creator_id: currentUser.id(),
      election_type: electionType,
      school_id: currentUser.currentSchoolYear().schoolId(),
      election_attributes: {
        name: "Valg",
        election_posts_attributes: electionPostAttributes()
      }
    })

    try {
      await schoolElection.save()

      if (electionType === "class_election") {
        navigate(ElectionRoutes.schoolElectionPath(schoolElection))
      } else {
        const newSchoolElection = await SchoolElection.ransack({id_eq: schoolElection.id()}).preload("election").first()

        navigate(ElectionRoutes.electionPath(newSchoolElection.election().id()))
      }
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }, [checked, currentUser, electionPosts, electionType, navigate, schoolClasses, schoolElection])

  const values = Object.values(checked)
  const allChecked = values.every((value) => value) && values.length === schoolClasses?.length
  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setChecked(Object.fromEntries(schoolClasses.map((schoolClass) => ([schoolClass.id(), true]))))
    } else {
      setChecked({})
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      {electionType === "class_election" &&
        <Alert variant="info">
          Klassevalg er valg, der omhandler en enkelt klasse. Hvis du vælger flere klasser i boksen nedenfor oprettes der således et valg per klasse.
        </Alert>
      }
      <input name="school_election[school_id]" type="hidden" value={currentUser.currentSchoolYear().schoolId() || ""} />
      <Card className="mb-3">
        <Card.Header className="cursor-default">
          {I18n.t("js.election.school_elections.new.choose_classes_for_the_election")}
        </Card.Header>
        <Card.Body>
          <div className="border-bottom mb-3 pb-3">
            <Form.Check
              checked={allChecked}
              id="school-class-check-all"
              label={I18n.t("js.election.school_elections.new.select_all")}
              onChange={onCheckAllChange}
            />
          </div>
          {schoolClasses.map((schoolClass) => (
            <Form.Check
              checked={checked[schoolClass.id()] || false}
              id={`form-check-school-class-${schoolClass.cacheKey()}`}
              key={schoolClass.id()}
              label={schoolClass.name()}
              onChange={onCheckChanged}
              type="checkbox"
              value={schoolClass.id()}
            />
          ))}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="cursor-default">{ElectionPost.modelName().human({count: 2})}</div>
          <Button onClick={() => setElectionPosts((electionPosts) => electionPosts.concat(new ElectionPost({seats: 1})))} size="sm" variant="secondary">
            <Icon icon="plus-circle" />
          </Button>
        </Card.Header>
        <Card.Body>
          <ListGroup>
            {electionPosts.map((electionPost, index) =>
              <ElectionPostGroup
                electionPost={electionPost}
                key={index}
                onChange={(e, attributeName) => setElectionPosts((electionPosts) =>
                  electionPosts.map((electionPostFromArray) => {
                    if (electionPostFromArray === electionPost) {
                      electionPostFromArray.assignAttributes({[attributeName]: e.target.value})
                    }

                    return electionPostFromArray
                  })
                )}
                onDestroyClicked={(e) => {
                  e.preventDefault()

                  setElectionPosts(electionPosts.filter((filteredElectionPost) => filteredElectionPost.cacheKey() !== electionPost.cacheKey()))
                }}
                showDestroy={electionPosts.length > 1}
              />
            )}
          </ListGroup>
        </Card.Body>
      </Card>
      <div className="mt-3">
        <SubmitButton disabled={!Object.values(checked).find((check) => check)}>
          {I18n.t("js.election.school_elections.new.create")}
        </SubmitButton>
      </div>
    </Form>
  )
}

