export default class ButtonsDestroy extends React.Component {
  static defaultProps = {
    small: false
  }

  static propTypes = PropTypesExact({
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    small: PropTypes.bool.isRequired
  })

  render() {
    const {children} = this.props

    return (
      <a className={this.className()} href="#" onClick={this.onClick} title={I18n.t("js.global.delete")}>
        {!children && <i className="la la-trash" />}
        {children}
      </a>
    )
  }

  className() {
    const {className, small} = this.props
    const classNames = ["components-buttons-destroy", "btn", "btn-secondary"]

    if (className) {
      classNames.push(className)
    }

    if (small) {
      classNames.push("btn-sm")
    }

    return classNames.join(" ")
  }

  onClick = async (e) => {
    e.preventDefault()

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    this.props.onClick()
  }
}
