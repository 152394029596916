import {electionPostAction, ElectionPostsStateSelector} from "admin/components/election-posts/state-selector"
import Button from "react-bootstrap/Button"
import Card from "components/card"
import {CustomError} from "@kaspernj/api-maker"
import ElectionPostContactCard from "./election-post-contact-card"
import numbersFromUuid from "shared/numbers-from-uuid"
import SeatsLabel from "nemoa/components/activities/elections/seats-label"
import Stack from "react-bootstrap/Stack"
import VoteIcon from "./vote-icon"
import VotingCounts from "./voting-counts"

export default class AdminActivitiesLiveAgendaItemTypesElectionElectionPostCard extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    electionPost: PropTypes.instanceOf(ElectionPost).isRequired,
    newStateSelector: PropTypes.bool
  })

  state = {
    electionPostContactIdToAnswerMapping: {},
    voteToken: undefined,
    voteTokenLoaded: false
  }

  componentDidMount() {
    const {electionPost} = this.props

    if (electionPost.state() === "election_active") {
      this.loadVoteToken()
    }
  }

  componentDidUpdate = ({electionPost: previousElectionPost}) => {
    const {electionPost} = this.props

    if (previousElectionPost.state() !== electionPost.state() && electionPost.state() === "election_active") {
      this.loadVoteToken()
    }
  }

  async loadVoteToken() {
    const {electionPost} = this.props

    let voteToken

    try {
      const response = await electionPost.findOrCreateVoteToken({})

      voteToken = digg(response, "vote_token")
    } catch (error) {
      if (error instanceof CustomError && this.isSilentVoteTokenError(error)) {
        // Safely ignore error because the component should be viewable in admin without being signed up
      } else {
        FlashMessage.errorResponse(error)
      }
    } finally {
      this.setState({voteToken, voteTokenLoaded: true})
    }
  }

  isSilentVoteTokenError = (error) => {
    const errorTypes = error.errorTypes()

    if (errorTypes.includes("contact_not_signed_up")) {
      return true
    }

    return errorTypes.includes("unqualified_to_vote")
  }

  render() {
    const {className, electionPost, newStateSelector} = this.props
    const {electionPostContactIdToAnswerMapping, voteToken} = this.state
    const electionPostContacts = electionPost.electionPostContacts().loaded()
    const orderedElectionPostContacts = electionPostContacts
      .sort((electionPostContact1, electionPostContact2) => electionPostContact1.position() - electionPostContact2.position())

    const translatedStates = translatedCollection("ElectionPost", "states")

    return (
      <Card.Outer
        className={classNames("admin-components-activities-live-agenda-item-types-election-election-post-card", className)}
        data-election-post-id={electionPost.id()}
        key={electionPost.id()}
      >
        {voteToken &&
          <EventUpdated model={voteToken} onUpdated={this.onVoteTokenUpdated} />
        }
        <Card.Header>
          <div className="d-flex">
            <div className="election-form-label me-auto">
              <Card.HeaderText>
                {I18n.t("js.admin.activities.live.agenda.item_types.election.index.ballot")}
              </Card.HeaderText>
              <Card.HeaderText>
                <span className="fst-italic fw-normal">
                  &nbsp;{electionPost.translatedElectionForm()}
                </span>
              </Card.HeaderText>
            </div>
            <div className="d-flex">
              {this.showVotingCounts() &&
                <VotingCounts className="me-2 votes-cast-vote-tokens-issued" electionPost={electionPost} textMuted />
              }
              {electionPost.state() === "closed" && electionPost.electionForm() === "trust" &&
                <Button className="generate-peaceful-result-button me-2" onClick={this.onGeneratePeacefulResultClicked} size="sm">
                  {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post_card.generate_peaceful_result")}
                </Button>
              }
              {this.showStateSelector() &&
                <>
                  {newStateSelector &&
                    <Stack direction="horizontal" gap={2}>
                      {electionPost.permittedTransitions().map(({event, state}) =>
                        <Button
                          key={state}
                          onClick={(e) => {
                            e.preventDefault()

                            electionPostAction(Inflection.underscore(event), electionPost)
                          }}
                          variant="primary"
                        >
                          {Object.entries(translatedStates).find(([, value]) => value === Inflection.underscore(state))[0]}
                        </Button>
                      )}
                    </Stack>
                  }
                  {!newStateSelector &&
                    <ElectionPostsStateSelector autoUpdate className="me-2" id="election_post_state_select" label={false} model={electionPost} size="small" />
                  }
                </>
              }
              {!this.showStateSelector() &&
                <Card.HeaderText className="election-post-translated-state">
                  {electionPost.translatedState()}
                </Card.HeaderText>
              }
            </div>
          </div>
        </Card.Header>
        <Card.SubHeader lightGrey>
          <div className="election">
            {electionPost.election().name()}
          </div>
          <Card.HeaderText>
            <div className="d-flex">
              <div className="me-auto">
                {electionPost.name()}
              </div>
              <div>
                <i className="la la-lg la-chair me-2" />
                [<SeatsLabel seats={electionPost.seats()} />]
              </div>
            </div>
          </Card.HeaderText>
        </Card.SubHeader>
        <Card.Body>
          <div className="border-bottom d-flex mb-4 pb-2 text-muted">
            <div className="me-auto">
              {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post.signed_up")}&nbsp;[{electionPostContacts.length}]
            </div>
            {this.showVoteButtons() &&
              <div className="required-number-of-answers-counter">
                [{this.numberOfAnswersGiven()}/{electionPost.votesRequired()}]
              </div>
            }
          </div>
          {orderedElectionPostContacts.map((electionPostContact, index) =>
            <ElectionPostContactCard
              answer={electionPostContactIdToAnswerMapping[electionPostContact.id()]}
              className={classNames({"mb-2": !this.isLastElement(index, electionPostContacts.length)})}
              electionPostContact={electionPostContact}
              key={electionPostContact.id()}
              onAnswerChanged={(answer) => this.onAnswerChanged(electionPostContact.id(), answer)}
              voteToken={voteToken}
            />
          )}
          <div className="border-top d-flex mt-4 pt-2">
            {this.showVoteButtons() &&
              <div className="d-flex vote-buttons">
                <form onSubmit={this.onSubmit}>
                  {this.hiddenInputs()}
                  <SubmitButton className="cast-vote-button" disabled={!this.submitButtonEnabled()}>
                    {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post.cast_vote")}
                  </SubmitButton>
                </form>
                <Button className="cast-blank-vote-button ms-2" onClick={this.onCastBlankVoteClicked}>
                  {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post.cast_blank_vote")}
                </Button>
              </div>
            }
            {this.showBlankVotesCount() &&
              <div className="align-items-center d-flex ms-auto">
                <VoteIcon className="me-2" icon="n" />
                <h1 className="election-post-blank-votes-count pb-1 pt-1 me-2 text-muted">
                  [{electionPost.voteElectionPostResult().blankVotesCount()}]
                </h1>
              </div>
            }
          </div>
        </Card.Body>
      </Card.Outer>
    )
  }

  onGeneratePeacefulResultClicked = async () => {
    const {electionPost} = this.props

    if (!await CustomConfirm.confirm()) {
      return
    }

    try {
      await electionPost.generateResult({action: "show_result", peaceful: true})
      const message = I18n.t("js.admin.activities.live.agenda.item_types.election.election_post_card.the_election_post_was_resolved_as_a_peaceful_election")

      FlashMessage.success(message)
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  submitButtonEnabled() {
    const {electionPost} = this.props

    if (electionPost.votesRequired() === this.numberOfAnswersGiven()) {
      return true
    }
  }

  showVoteButtons() {
    const {electionPost} = this.props
    const {voteToken} = this.state

    if (electionPost.state() !== "election_active") {
      return false
    }

    if (!voteToken || voteToken.voteCasted()) {
      return false
    }

    return true
  }

  showStateSelector() {
    const {electionPost} = this.props

    return electionPost.can("activateElection") ||
      electionPost.can("close") ||
      electionPost.can("generateResult") ||
      electionPost.can("markAsDraft") ||
      electionPost.can("markAsOpenForRegistration")
  }

  showVotingCounts() {
    const {electionPost} = this.props

    return electionPost.can("readVotingCounts")
  }

  showBlankVotesCount() {
    const {electionPost} = this.props

    if (electionPost.state() !== "processing_result" && electionPost.state() !== "result_shown") {
      return false
    }

    if (!electionPost.voteElectionPostResult()) {
      return false
    }

    if (!electionPost.voteElectionPostResult().isAttributeLoaded("blankVotesCount")) {
      return false
    }

    return true
  }

  isLastElement(index, length) {
    if (index == length - 1) {
      return true
    }

    return false
  }

  onAnswerChanged(electionPostContactId, answer) {
    const electionPostContactIdToAnswerMapping = {...this.state.electionPostContactIdToAnswerMapping}

    if (electionPostContactIdToAnswerMapping[electionPostContactId] == answer) {
      delete electionPostContactIdToAnswerMapping[electionPostContactId]
    } else {
      electionPostContactIdToAnswerMapping[electionPostContactId] = answer
    }

    this.setState({electionPostContactIdToAnswerMapping})
  }

  onVoteTokenUpdated = ({model: voteToken}) => {
    this.setState({voteToken})
  }

  onCastBlankVoteClicked = async () => {
    const {voteToken} = this.state

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    try {
      await voteToken.castVote({blank_vote: true})
      FlashMessage.success(I18n.t("js.admin.activities.live.agenda.item_types.election.election_post.your_vote_was_casted"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  numberOfAnswersGiven() {
    const {electionPostContactIdToAnswerMapping} = this.state
    const numberOfAnswersGiven = Object.entries(electionPostContactIdToAnswerMapping).length

    return numberOfAnswersGiven
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {voteToken} = this.state
    const form = digg(e, "target")
    const formData = new FormData(form)

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    try {
      await voteToken.castVote(formData)
      FlashMessage.success(I18n.t("js.admin.activities.live.agenda.item_types.election.election_post.your_vote_was_casted"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  hiddenInputs() {
    const {electionPostContactIdToAnswerMapping} = this.state

    return Object.entries(electionPostContactIdToAnswerMapping).map(([electionContactPostId, answer]) =>
      <React.Fragment key={electionContactPostId}>
        <input name={`vote_election_post_contacts_attributes[${numbersFromUuid(electionContactPostId)}][answer]`} type="hidden" value={answer} />
        <input
          name={`vote_election_post_contacts_attributes[${numbersFromUuid(electionContactPostId)}][election_post_contact_id]`}
          type="hidden"
          value={electionContactPostId}
        />
      </React.Fragment>
    )
  }
}
