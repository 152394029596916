import "./style"
import SessionsNew from "components/sessions/new"
import {useCallback} from "react"
import {useNavigate} from "react-router-dom"

export default function Index() {
  const navigate = useNavigate()
  const onSignedIn = useCallback(() => navigate("/"), [navigate])

  return (
    <div className="d-flex" style={{minHeight: "100%"}}>
      <SessionsNew
        authenticateAnonymously={false}
        className="align-self-center mx-auto"
        onSignedIn={onSignedIn}
        showLogo={false}
        style={{minWidth: "500px"}}
      />
    </div>
  )
}
