import {useQuery} from "react-query"

const loadElectionPosts = ({electionId, schoolClassId, schoolElectionId}) => ElectionPost
  .ransack({
    s: "position",
    visible_true: true,
    ...(schoolClassId && {school_classes_id_in: schoolClassId}),
    ...(schoolElectionId && {election_school_election_id_eq: schoolElectionId}),
    ...(electionId && {election_id_eq: electionId})
  })
  .select({
    Contact: ["id", "name"],
    ElectionPost: [
      "deadline",
      "electionForm",
      "id",
      "name",
      "permittedTransitions",
      "seats",
      "state",
      "subTitle",
      "translatedElectionForm",
      "translatedState",
      "visible",
      "votesCount",
      "voteTokensCount",
      "votesRequired"
    ],
    Organization: ["id"],
    Person: ["profileImagePath"],
    VoteElectionPostContactResult: ["blankVotesCount", "noTrustVotesCount", "result", "translatedResult", "trustVotesCount", "yesVotesCount"],
    VoteElectionPostResult: ["blankVotesCount"]
  })
  .abilities({
    ElectionPost: ["activateElection", "close", "generateResult", "markAsDraft", "markAsOpenForRegistration", "readVotingCounts"]
  })
  .preload([
    "election_post_contacts.contact.person",
    "election_post_contacts.contact.school.contact.commune",
    "election_post_contacts.contact.school.contact.region",
    "election_post_contacts.election_post",
    "election_post_contacts.vote_election_post_contact_result",
    "election",
    "vote_election_post_result"
  ])
  .toArray()

export function useElectionPosts(args) {
  return useQuery(["election_posts", args], () => loadElectionPosts(args))
}
