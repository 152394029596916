import ContactCard from "admin/components/activities/live/agenda/contact-card"
import ElectionAnswer from "./answer"
import ElectionResult from "./result"

export default class AdminActivitiesLiveAgendaItemTypesElectionElectionPostContactCard extends React.Component {
  static propTypes = PropTypesExact({
    answer: PropTypes.string,
    className: PropTypes.string,
    electionPostContact: PropTypes.instanceOf(ElectionPostContact).isRequired,
    onAnswerChanged: PropTypes.func.isRequired,
    voteToken: PropTypes.instanceOf(VoteToken)
  })

  render() {
    const {className} = this.props
    const {electionPostContact} = digs(this.props, "electionPostContact")

    return (
      <ContactCard
        actionsContent={this.actionsContent()}
        className={classNames("admin-components-activities-live-agenda-item-types-election-election-post-contact-card", className)}
        contact={electionPostContact.contact()}
        data-election-post-contact-id={electionPostContact.id()}
      />
    )
  }

  actionsContent() {
    const {electionPostContact} = digs(this.props, "electionPostContact")
    const electionPost = electionPostContact.electionPost()

    return (
      <div className="align-self-center d-flex">
        <Choose>
          <When condition={electionPost.state() == "election_active"}>
            {this.electionActiveContent()}
          </When>
          <When condition={["result_shown", "processing_result"].includes(electionPost.state()) && electionPostContact.voteElectionPostContactResult()}>
            <ElectionResult
              className="election-result pe-3"
              electionPost={electionPost}
              title={electionPostContact.voteElectionPostContactResult().translatedResult()}
              voteElectionPostContactResult={electionPostContact.voteElectionPostContactResult()}
            />
          </When>
        </Choose>
      </div>
    )
  }

  electionActiveContent() {
    const {voteToken} = this.props

    return (
      <div className="d-flex election-active-content me-2">
        <Choose>
          <When condition={voteToken}>
            {this.voteTokenContent()}
          </When>
          <Otherwise>
            <div className="no-vote-token pe-2">
              {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post_contact_card.you_cannot_vote")}
            </div>
          </Otherwise>
        </Choose>
      </div>
    )
  }

  voteTokenContent() {
    const {answer, voteToken} = digs(this.props, "answer", "voteToken")

    return (
      <>
        {voteToken.voteCasted() &&
          <div className="pe-2">
            {I18n.t("js.admin.activities.live.agenda.item_types.election.election_post_contact_card.vote_casted")}
          </div>
        }
        {!voteToken.voteCasted() && this.answers().map(({icon, value}, index) =>
          <ElectionAnswer
            answer={value}
            checked={answer == value}
            className={classNames("vote-button", {"me-2": !this.isLastElement(index, this.answers().length)})}
            icon={icon}
            key={value}
            onClick={(e) => this.onAnswerClicked(e, value)}
          />
        )}
      </>
    )
  }

  isLastElement(index, length) {
    if (index + 1 == length) {
      return true
    }

    return false
  }

  onAnswerClicked(e, answer) {
    e.preventDefault()

    const {onAnswerChanged} = digs(this.props, "onAnswerChanged")

    onAnswerChanged(answer)
  }

  answers() {
    const {electionPostContact} = digs(this.props, "electionPostContact")
    const electionForm = electionPostContact.electionPost().electionForm()

    if (electionForm == "contest") {
      return [
        {
          icon: "thumbs-up",
          value: "yes"
        }
      ]
    } else if (electionForm == "trust") {
      return [
        {
          icon: "n",
          value: "blank_vote"
        },
        {
          icon: "thumbs-down",
          value: "no_trust"
        },
        {
          icon: "thumbs-up",
          value: "trust"
        }
      ]
    }
  }
}
