import SharedPluralizedTranslation from "components/pluralized-translation"

export default class ActivitiesElectionsSeatsLabel extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    seats: PropTypes.number.isRequired
  })

  render() {
    const {className} = this.props
    const {seats} = digs(this.props, "seats")

    return (
      <SharedPluralizedTranslation
        className={classNames("nemoa-components-activities-elections-seats-label", className)}
        count={seats}
        one={I18n.t("js.activities.elections.election_post.seat")}
        other={I18n.t("js.activities.elections.election_post.seats", {seats})}
      />
    )
  }
}
