export default class StateSelector extends BaseComponent {
  static defaultProps = {
    autoUpdate: false
  }

  static propTypes = {
    autoUpdate: PropTypes.bool.isRequired,
    className: PropTypes.string,
    model: PropTypes.object.isRequired,
    saveAction: PropTypes.func,
    stateToActionMapping: PropTypes.object
  }

  events = new EventEmitter()
  state = ({
    translatedStates: this.translatedStates(this.props.model, this.props.stateToActionMapping)
  })

  constructor(props) {
    super(props)

    if (props.autoUpdate && !props.stateToActionMapping) {
      throw new Error("autoUpdate cannot be set without a state to action map")
    }
  }

  componentDidUpdate = (prevProps) => {
    const {events} = digs(this, "events")
    const {autoUpdate, model} = digs(this.props, "autoUpdate", "model")

    if (!autoUpdate) {
      return
    }

    const previousModel = digg(prevProps, "model")

    if (model.state() != previousModel.state()) {
      if (!("translatedState" in model)) {
        throw new Error(`The given model of ${digg(model.modelClassData(), "name")} didn't have a translatedState method`)
      }

      events.emit("setValue", [
        {
          label: model.translatedState(),
          value: model.state()
        }
      ])
    }
  }

  translatedStates(model, stateToActionMapping) {
    const modelClassName = digg(model.modelClassData(), "name")
    const translatedStates = translatedCollection(modelClassName, "states")

    if (stateToActionMapping) {
      Object.values(translatedStates).forEach((state) => {
        if (!(state in stateToActionMapping)) {
          throw new Error(`State ${state} not handled in stateToActionMapping`)
        }
      })
    }

    return translatedStates
  }

  render() {
    const {autoUpdate, className, model, saveAction, stateToActionMapping, ...restProps} = this.props
    const {translatedStates} = this.state
    const {events} = digs(this, "events")

    return (
      <NemoaSelect
        attribute="state"
        className={classNames("components-shared-state-selector", className)}
        events={events}
        id={`state-selector-${model.id()}`}
        model={model}
        onChange={this.onStateChanged}
        options={HashToCollection.toCollectionArray(translatedStates)}
        {...restProps}
      />
    )
  }

  onStateChanged = async ({option}) => {
    const {autoUpdate, model, saveAction} = this.props
    const newState = digg(option, "value")

    if (autoUpdate) {
      const {stateToActionMapping} = this.props
      const action = digg(stateToActionMapping, newState)

      try {
        if (saveAction) {
          await saveAction(action)
        } else {
          await User.stateCall({
            action,
            model_class: model.modelClassData().name,
            model_id: model.id()
          })
        }

        FlashMessage.success(I18n.t("js.shared.state_selector.state_was_updated"))
      } catch (error) {
        FlashMessage.errorResponse(error)
      }
    }
  }
}
