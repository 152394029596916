import {generatePath, useNavigate, useParams} from "react-router-dom"
import {useContext, useEffect, useState} from "react"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import {UserContext} from "shared/user-context"
import {useSchoolElection} from "shared/hooks/queries/use-school-election"

function YourSchoolElection({schoolElection}) {
  const navigate = useNavigate()

  return (
    <>
      <h1>{schoolElection.translatedElectionType()}</h1>
      <Row>
        <Col md={6}>
          <Card bg="primary" onClick={() => navigate(`/school_elections/${schoolElection.id()}/vote`)} style={{cursor: "pointer"}} text="light">
            <Card.Header>Stem</Card.Header>
            <Card.Body>
              Klik her, hvis du vil afgive din stemme.
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card bg="success" onClick={() => navigate(`/school_elections/${schoolElection.id()}/sign_up`)} style={{cursor: "pointer"}} text="light">
            <Card.Header>Stil op til en valgpost</Card.Header>
            <Card.Body>
              Klik her, hvis du vil stille op til et valg.
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default function SchoolElectionsShow() {
  const {id: schoolElectionId} = useParams()
  const [schoolClasses, setSchoolClasses] = useState()
  const navigate = useNavigate()
  const [currentUser] = useContext(UserContext)
  const {data: schoolElection} = useSchoolElection({id: schoolElectionId})

  useEffect(() => {
    async function loadSchoolClasses() {
      const schoolClasses = await SchoolClass
        .ransack({elections_school_election_id_eq: schoolElectionId, s: "name asc"})
        .distinct()
        .toArray()

      setSchoolClasses(schoolClasses)
    }

    loadSchoolClasses()
  }, [schoolElectionId])

  return (
    <div>
      {!currentUser.currentSchoolYear().teaching() &&
        <Choose>
          <When condition={currentUser.currentSchoolYear().schoolClassId()}>
            {schoolElection &&
              <YourSchoolElection schoolElection={schoolElection}/>
            }
          </When>
          <Otherwise>
            Du er ikke tilknyttet en skoleklasse.
          </Otherwise>
        </Choose>
      }
      {currentUser.currentSchoolYear().teaching() &&
        <div className="row">
          <div className="col-md-8">
            <ListGroup>
              {schoolElection && schoolClasses?.map((schoolClass) =>
                <ListGroup.Item
                  action
                  key={schoolClass.id()}
                  onClick={() => navigate(generatePath(`/elections/:id?school_class_id=${schoolClass.id()}`, {id: schoolElection.election()?.id()}))}
                >
                  {schoolClass.name()}
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>
          <div className="col-md-4">
            <Alert variant="info">
              Et valg starter med at være kladde. I denne fase opsættes relevante poster mv.
              Herefter bliver valget gjort åbent for tilmelding.
            </Alert>
          </div>
        </div>
      }
    </div>
  )
}
