import {SchoolElection} from "@kaspernj/api-maker/src/models"
import {tenantAtom} from "../../atoms/tenant-atom"
import {useContext} from "react"
import {useQuery} from "react-query"
import {UserContext} from "../../user-context"
import {useRecoilValue} from "recoil"

export function useSchoolElection({id} = {}) {
  const tenant = useRecoilValue(tenantAtom)
  const [currentUser] = useContext(UserContext)

  const params = id ? {id_eq: id} : {
    school_id_eq: schoolId,
    tenant_id_eq: tenant.id()
  }
  const schoolId = currentUser.currentSchoolYear().school().id()
  const loadSchoolElection = () => SchoolElection.ransack(params).preload("election").first()

  return useQuery(["school_elections", params], loadSchoolElection)
}
