export default class SharedPluralizedTranslation extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    count: PropTypes.number.isRequired,
    one: PropTypes.string.isRequired,
    other: PropTypes.string.isRequired,
    zero: PropTypes.string
  })

  render() {
    const {className} = this.props

    return (
      <span className={classNames("components-shared-pluralized-translation", className)}>
        {this.label()}
      </span>
    )
  }

  label() {
    const {count, one, other} = digs(this.props, "count", "one", "other")
    const {zero} = this.props

    if (count == 0 && zero) {
      return zero
    } else if (count == 1) {
      return one
    }

    return other
  }
}
