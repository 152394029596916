import "./style"
import Body from "./body"
import ExpandableBody from "./expandable-body"
import Header from "./header"
import HeaderText from "./header-text"
import Outer from "./outer"
import Row from "./row"
import SubHeader from "./sub-header"

export default {Body, ExpandableBody, Header, HeaderText, Outer, Row, SubHeader}
