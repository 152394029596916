import {useParams, useSearchParams} from "react-router-dom"
import ElectionPostCard from "admin/components/activities/live/agenda/item-types/election/election-post-card"
import Stack from "react-bootstrap/Stack"
import {useElectionPosts} from "shared/hooks/queries/use-election-posts"

export default function ElectionsShow() {
  const {id: electionId} = useParams()
  const [searchParams] = useSearchParams()
  const schoolClassId = searchParams.get("school_class_id")
  const {data: electionPosts, refetch} = useElectionPosts({electionId, schoolClassId})

  return (
    <Stack gap={3}>
      {electionPosts?.map((electionPost) =>
        <React.Fragment key={electionPost.id()}>
          <EventUpdated model={electionPost} onUpdated={() => refetch()} />
          <ElectionPostCard electionPost={electionPost} newStateSelector />
        </React.Fragment>
      )}
    </Stack>
  )
}
