import VoteIcon from "admin/components/activities/live/agenda/item-types/election/vote-icon"

export default class AdminActivitiesLiveAgendaItemTypesElectionResult extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    electionPost: PropTypes.instanceOf(ElectionPost).isRequired,
    title: PropTypes.string.isRequired,
    voteElectionPostContactResult: PropTypes.instanceOf(VoteElectionPostContactResult).isRequired
  }

  render() {
    const {className} = this.props
    const {electionPost, title, voteElectionPostContactResult} = digs(this.props, "electionPost", "title", "voteElectionPostContactResult")

    return (
      <div
        className={classNames("admin-components-activities-live-agenda-item-types-election-result", className)}
        data-result={voteElectionPostContactResult.result()}
        title={title}
      >
        {electionPost.electionForm() == "contest" && this.contestContent()}
        {electionPost.electionForm() == "trust" && this.trustContent()}
      </div>
    )
  }

  contestContent() {
    const {voteElectionPostContactResult} = digs(this.props, "voteElectionPostContactResult")

    return (
      <div className="align-items-center contest-content d-flex">
        <VoteIcon
          className="me-2"
          disabled={voteElectionPostContactResult.result() != "yes"}
          icon="selected"
        />
        {voteElectionPostContactResult.isAttributeLoaded("yesVotesCount") &&
          <h1 className="text-muted yes-votes-count">
            [{voteElectionPostContactResult.yesVotesCount()}]
          </h1>
        }
      </div>
    )
  }

  trustContent() {
    const {voteElectionPostContactResult} = digs(this.props, "voteElectionPostContactResult")

    return (
      <div className="align-items-center d-flex trust-content">
        <VoteIcon className="me-2" disabled icon="n" />
        {voteElectionPostContactResult.isAttributeLoaded("blankVotesCount") &&
          <h1 className="blank-votes-count me-2 text-muted">
            [{voteElectionPostContactResult.blankVotesCount()}]
          </h1>
        }
        <VoteIcon className="me-2" disabled={voteElectionPostContactResult.result() != "no_trust"} icon="thumbs-down" />
        {voteElectionPostContactResult.isAttributeLoaded("noTrustVotesCount") &&
          <h1 className="me-2 no-trust-votes-count text-muted">
            [{voteElectionPostContactResult.noTrustVotesCount()}]
          </h1>
        }
        <VoteIcon disabled={voteElectionPostContactResult.result() != "trust"} icon="thumbs-up" />
        {voteElectionPostContactResult.isAttributeLoaded("trustVotesCount") &&
          <h1 className="ms-2 text-muted trust-votes-count">
            [{voteElectionPostContactResult.trustVotesCount()}]
          </h1>
        }
      </div>
    )
  }
}
