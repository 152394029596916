import VoteIcon from "./vote-icon"

export default class AdminActivitiesLiveAgendaItemTypesElectionAnswer extends React.Component {
  static propTypes = PropTypesExact({
    answer: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    icon: PropTypes.oneOf(["n", "thumbs-down", "thumbs-up"]).isRequired,
    onClick: PropTypes.func.isRequired
  })

  render() {
    const {answer, checked, icon, onClick} = digs(this.props, "answer", "checked", "icon", "onClick")
    const {className} = this.props

    return (
      <VoteIcon
        className={classNames("admin-components-activities-live-agenda-item-types-election-answer", className)}
        data-answer={answer}
        data-checked={checked}
        disabled={!checked}
        icon={icon}
        onClick={onClick}
        size="extra-large"
      />
    )
  }
}
