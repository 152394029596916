export default class CardOuter extends React.Component {
  static defaultProps = {
    compact: false
  }

  static propTypes = {
    compact: PropTypes.bool.isRequired
  }

  render() {
    const {className, compact, ...restProps} = this.props

    return (
      <div className={classNames("components-card-outer", className, {compact})} {...restProps} />
    )
  }
}
