import "./style"
import PersonImage from "./person-image"

export default class AdminActivitiesLiveAgendaContactCardIndex extends React.Component {
  static propTypes = {
    actionsContent: PropTypes.node,
    className: PropTypes.string,
    contact: PropTypes.instanceOf(Contact).isRequired,
    onDragStart: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragOver: PropTypes.func,
    onDrop: PropTypes.func
  }

  state = ({
    departments: undefined
  })

  componentDidMount() {
    this.loadDepartments()
  }

  async loadDepartments() {
    const {contact} = digs(this.props, "contact")
    const response = await contact.departments()
    const departments = digg(response, "departments")

    this.setState({departments})
  }

  render() {
    const {actionsContent, className, contact, onDragStart, onDragLeave, onDragOver, onDrop, ...restProps} = this.props
    const person = contact.person()
    const profileImage = person?.profileImagePath() || PersonImage
    const relationships = this.relationships()

    return (
      <div className={classNames("admin-components-activities-live-agenda-contact-card d-flex", className)} {...restProps}>
        <div className="profile-image-border-container">
          <div
            className="profile-image-container"
            draggable={Boolean(onDragStart)}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDragStart={onDragStart}
            onDrop={onDrop}
            style={{backgroundImage: `url('${profileImage}`}}
          />
        </div>
        <div className="align-self-center flex-grow-1">
          {contact &&
            <>
              <div>
                <ContactLink contact={contact} />
              </div>
              <div className="speaker-sub-text">
                <small>
                  {relationships.map((relationshipData, index) =>
                    <React.Fragment key={relationshipData.contact.id()}>
                      <ContactLink className={`speaker-${digg(relationshipData, "type")}-link`} contact={digg(relationshipData, "contact")} />
                      {index < (relationships.length - 1) &&
                        <span className="me-1">,</span>
                      }
                    </React.Fragment>
                  )}
                </small>
              </div>
            </>
          }
        </div>
        {actionsContent}
      </div>
    )
  }

  relationships() {
    const {contact} = digs(this.props, "contact")
    const school = contact.school()
    const commune = school?.contact()?.commune()
    const region = school?.contact()?.region()
    const {departments} = digs(this.state, "departments")
    const relationshipContacts = []

    if (region) {
      relationshipContacts.push({contact: region, type: "region"})
    }

    if (commune) {
      relationshipContacts.push({contact: commune, type: "commune"})
    }

    if (school?.contact()) {
      relationshipContacts.push({contact: school.contact(), type: "school"})
    }

    if (departments) {
      for (const department of departments) {
        relationshipContacts.push({contact: department, type: "department"})
      }
    }

    return relationshipContacts
  }
}
