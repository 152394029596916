import {useContext, useEffect} from "react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import Stack from "react-bootstrap/Stack"
import {useNavigate} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useSchoolElection} from "shared/hooks/queries/use-school-election"

function YourSchoolElectionOrNone({children}) {
  const {data: schoolElection, isLoading} = useSchoolElection()
  const navigate = useNavigate()

  useEffect(() => {
    if (schoolElection) {
      navigate(`/school_elections/${schoolElection.id()}`)
    }
  }, [schoolElection])

  if (isLoading) {
    return (
      <div className="d-flex">
        <Spinner animation="border" className="me-2" variant="primary"/>
        Finder det aktive skolevalg..
      </div>
    )
  }

  if (!schoolElection) {
    return (
      {children}
    )
  }

  return null
}

export default function Home() {
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()

  return (
    <div className="election-routes-home">
      {currentUser.currentSchoolYear() &&
        <div>
          <h1 className="cursor-default school-name">
            {currentUser.currentSchoolYear().school().name()}
          </h1>
          {currentUser.currentSchoolYear().teaching() &&
            <YourSchoolElectionOrNone>
              <div>
                <div className="cursor-default mb-2">
                  Velkommen til valg
                </div>
                <Row className="g-3">
                  <Col lg={4} md={12}>
                    <Card>
                      <Card.Body>
                        <Card.Title className="cursor-default">
                          Opret klassevalg
                        </Card.Title>
                        Klasser valg, er valg der omhandler enkelte klasser.
                        <Stack>
                          <Button
                            className="mt-3"
                            onClick={() => navigate("school_elections/new?school_election[election_type]=class_election")}
                            variant="primary"
                          >
                            Opret klassevalg
                          </Button>
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={4} md={12}>
                    <Card>
                      <Card.Body>
                        <Card.Title className="cursor-default">
                          Opret skolevalg
                        </Card.Title>
                        Opret skolevalg for alle skolens klasser.

                        <Stack>
                          <Button
                            className="mt-3"
                            onClick={() => navigate("school_elections/new?school_election[election_type]=general_meeting_election")}
                            variant="primary"
                          >
                            Opret skolevalg
                          </Button>
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={4} md={12}>
                    <Card>
                      <Card.Body>
                        <Card.Title className="cursor-default">
                          Opret kredsvalg
                        </Card.Title>
                        Opret kredsvalg.

                        <Stack>
                          <Button
                            className="mt-3"
                            onClick={() => navigate("school_elections/new?school_election[election_type]=constituency_election")}
                            variant="primary"
                          >
                            Opret kredsvalg
                          </Button>
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </YourSchoolElectionOrNone>
          }
          {!currentUser.currentSchoolYear().teaching() &&
            <Choose>
              <When condition={currentUser.currentSchoolYear().schoolClassId()}>
                <YourSchoolElectionOrNone>
                  <Alert variant="danger">
                    Der blev ikke fundet et valg for din skole.
                  </Alert>
                </YourSchoolElectionOrNone>
              </When>
              <Otherwise>
                Du er ikke tilknyttet en skoleklasse.
              </Otherwise>
            </Choose>
          }
        </div>
      }
      {!currentUser.currentSchoolYear() &&
        <div>
          Du er ikke tilknyttet en skole og kan derfor ikke se eller administrere valg.
          TODO Vi skal vise eventuelle skoleår her
        </div>
      }
    </div>
  )
}
