export default class CardSubHeader extends React.Component {
  static defaultProps = {
    lightGrey: false
  }

  static propTypes = {
    className: PropTypes.string,
    lightGrey: PropTypes.bool.isRequired
  }

  render() {
    const {className, lightGrey, ...restProps} = this.props

    return (
      <div className={classNames("components-card-sub-header", className, {"light-grey": lightGrey})} {...restProps} />
    )
  }
}
