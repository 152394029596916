// Extracts numbers from a given UUID and returns a maximum of 15 of them without leading zeros as a number.
export default function numbersFromUuid(uuid) {
  let numbersAsString = uuid
    .match(/\d+/g)
    .join("")
    .replace(/^0+/, "")

  if (numbersAsString.length > 15) {
    numbersAsString = numbersAsString.substring(0, 15)
  }

  return Number(numbersAsString)
}
