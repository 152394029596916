import StateSelector from "components/state-selector"

export function electionPostAction(action, electionPost) {
  if (action === "activate_election") {
    return electionPost.activateElection()
  }

  if (action === "process_result" || action === "show_result") {
    return electionPost.generateResult({action})
  }

  return User.stateCall({
    action,
    model_class: "ElectionPost",
    model_id: electionPost.id()
  })
}

export class ElectionPostsStateSelector extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }

  render() {
    const {className, model: electionPost, ...restProps} = this.props

    return (
      <StateSelector
        autoUpdate
        className={classNames("admin-components-election-posts-state-selector", className)}
        model={electionPost}
        saveAction={(action) => electionPostAction(action, electionPost)}
        stateToActionMapping={{
          draft: "mark_as_draft",
          open_for_registration: "mark_as_open_for_registration",
          closed: "close",
          election_active: "activate_election",
          processing_result: "process_result",
          result_shown: "show_result"
        }}
        {...restProps}
      />
    )
  }
}
