import Card from "components/card"
import SeatsLabel from "nemoa/components/activities/elections/seats-label"

export default class ActivitiesElectionsElectionPostCard extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    contactId: PropTypes.string.isRequired,
    electionPost: PropTypes.instanceOf(ElectionPost).isRequired
  })

  state = {
    electionPostContact: undefined,
    electionPostContactLoaded: false
  }

  componentDidMount() {
    this.loadElectionPostContact()
  }

  // TODO this should be loaded through the electionPostProp
  async loadElectionPostContact() {
    const {contactId, electionPost} = this.props
    const electionPostContact = await ElectionPostContact
      .ransack({
        contact_id_eq: contactId,
        election_post_id_eq: electionPost.id()
      })
      .abilities({
        ElectionPostContact: ["destroy"]
      })
      .first()

    this.setState({electionPostContact, electionPostContactLoaded: true})
  }

  render() {
    const {className, electionPost} = this.props
    const {electionPostContactLoaded} = this.state

    return (
      <Card.Outer className={classNames("nemoa-components-activities-elections-election-post", className)} data-election-post-id={electionPost.id()}>
        <Card.Body className="d-flex flex-column justify-content-between election-post-card-body h-100">
          <div className="mb-5 text-center text-success titles-container">
            <h2>{electionPost.name()}</h2>
            {electionPost.hasSubTitle() &&
              <p className="sub-title">
                {electionPost.subTitle()}
              </p>
            }
          </div>
          <div className="election-post-contents-container">
            {electionPostContactLoaded &&
              <div className="election-actions">
                {this.actionButton()}
              </div>
            }
            <div className="border-top d-flex pt-2 text-muted">
              <div className="deadline me-auto">
                {I18n.t("js.activities.elections.election_post.deadline_for_sign_up")}
                {I18n.l("time.formats.short", electionPost.deadline())}
              </div>
              <div className="seats">
                <i className="la la-chair me-2" />
                (<SeatsLabel seats={electionPost.seats()} />)
              </div>
            </div>
          </div>
        </Card.Body>
      </Card.Outer>
    )
  }

  onWithdrawClicked = async () => {
    const {electionPostContact} = this.state

    try {
      await electionPostContact.destroy()
      this.setState({electionPostContact: undefined})
      FlashMessage.success(I18n.t("js.activities.elections.election_post.sign_up_withdrawn"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onSignUpClicked = async () => {
    const {contactId, electionPost} = this.props
    const electionPostContact = new ElectionPostContact({contact_id: contactId, election_post_id: electionPost.id()})

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    try {
      await electionPostContact.save()
      this.loadElectionPostContact()
      FlashMessage.success(I18n.t("js.activities.elections.election_post.signed_up"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  actionButton() {
    const {electionPost} = this.props
    const {electionPostContact} = this.state

    if (electionPostContact?.can("destroy")) {
      return (
        <DestroyButton className="mb-2 w-100 withdraw-sign-up-to-election-post-button" onClick={this.onWithdrawClicked}>
          {I18n.t("js.activities.elections.election_post.withdraw")}
        </DestroyButton>
      )
    }

    if (electionPost.state() === "open_for_registration") {
      return (
        <Button className="mb-2 sign-up-to-election-post-button w-100" onClick={this.onSignUpClicked} primary>
          {I18n.t("js.activities.elections.election_post.sign_up")}
        </Button>
      )
    }
  }
}
