import "controllers"
import {BrowserRouter, Outlet, Route, Routes, useNavigate} from "react-router-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import {Suspense, useContext, useEffect, useState} from "react"
import {App} from "components/app"
import ElectionsShow from "../election/routes/elections/show"
import FlashMessages from "nemoa/components/flash-messages"
import Home from "../election/routes/home"
import Layout from "election/components/layout"
import ReactDOM from "react-dom"
import SchoolElectionsNew from "../election/routes/school-elections/new"
import SchoolElectionsShow from "../election/routes/school-elections/show"
import SchoolElectionsSignUp from "../election/routes/school-elections/sign-up"
import SchoolElectionsVote from "../election/routes/school-elections/vote"
import ScrollToTop from "shared/scroll-to-top"
import SignIn from "../election/routes/sign-in"
import {UserContext} from "shared/user-context"

require("stylesheets/application")
require("stylesheets/contained-image")
require("shared/devise")
require("shared/error-logger")
require("shared/i18n")
require("shared/session-status-updater")

function AuthenticatedProxy() {
  const [currentUser] = useContext(UserContext)
  const [schoolYearLoaded, setSchoolYearLoaded] = useState(false)
  const [contactLoaded, setContactLoaded] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      currentUser.loadCurrentSchoolYear().then(() => {
        if (currentUser.currentSchoolYear()) {
          currentUser.currentSchoolYear().loadSchool().then(() => setSchoolYearLoaded(true))
        } else {
          setSchoolYearLoaded(true)
        }
      })
      currentUser.loadContact().then(() => setContactLoaded(true))
    } else {
      navigate("/sign_in")
    }
  }, [currentUser]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentUser || !schoolYearLoaded || !contactLoaded) {
    return null
  }

  return <Outlet />
}

const queryClient = new QueryClient()

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")

  ReactDOM.render((
    <BrowserRouter>
      <App>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <FlashMessages />
          <Suspense fallback={<div />}>
            <Routes>
              <Route element={<SignIn />} path="/sign_in" />
              <Route element={<AuthenticatedProxy />} path="/">
                <Route element={<Layout />} path="/">
                  <Route element={<Home />} path="/" />
                  <Route path="/school_elections">
                    <Route element={<SchoolElectionsNew />} path="new" />
                    <Route element={<SchoolElectionsShow />} path=":id" />
                    <Route element={<SchoolElectionsVote />} path=":id/vote" />
                    <Route element={<SchoolElectionsSignUp />} path=":id/sign_up" />
                  </Route>
                  <Route element={<ElectionsShow />} path="/elections/:id" />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </QueryClientProvider>
      </App>
    </BrowserRouter>
  ), reactRoot)
})
